export default function sanitizeQuery(query) {
    if (!query) return ''; // Return empty string if query is falsy
    
    // Remove parentheses and any other unwanted characters
    const sanitizedQuery = query
      .trim() // Remove leading and trailing whitespace
      .replace(/[()]/g, '') // Remove parentheses
      .replace(/[^\w\s|]/g, '') // Remove any non-alphanumeric characters except space and pipe
      .replace(/\s+/g, ' | '); // Replace spaces with logical OR (|)
  
    return sanitizedQuery;
}