import { useMutation, useQuery } from '@apollo/client';
import {
  GetInspectionsDocument,
  InspectionDueType,
  GetIntervalsForCraftDocument,
  CreateInspectionDocument,
  InspectionCreateInput,
  UpdateInspectionDocument,
  GetInspectionDocument,
  DeleteInspectionDocument,
  UpdateIntervalsOnInspectionsDocument,
  InspectionUpdateInput,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { FormLabel, FullFormikCheckBox, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import SegmentControl from 'components/SegmentControl/SegmentControl';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import { useEffect, useState } from 'react';
import { webFormat } from 'utils/statuses';
import Modal from 'components/Modal/Modal';

const EditInspectionFlyout = function ( { closeFlyout, id } : { closeFlyout: (didDelete: boolean) => void; id: string; } ) {
    const { craftId } = useSession();
    const [ modal, setModal ] = useState(false);
    const { data: { inspection } = {} }  = useQuery(GetInspectionDocument, { variables: { id } });
    const [ updateInspection ] = useMutation(UpdateInspectionDocument, { refetchQueries: [{ query: GetInspectionsDocument, variables: { craftId } }, { query: GetInspectionDocument, variables: { id } }] });
    const [ updateIntervalsOnInspections ] = useMutation(UpdateIntervalsOnInspectionsDocument);
    const [ deleteInspection ] = useMutation(DeleteInspectionDocument, { refetchQueries: [{ query: GetInspectionsDocument, variables: { craftId } }], onCompleted: () => closeFlyout(true) });

    const { data: { craft : { normalIntervals: intervals } = {} } = {} } = useQuery(GetIntervalsForCraftDocument, { variables: { craftId } });
    const [ initialValues, setInitialValues ] = useState({
        name: inspection?.name,
        description: inspection?.description,
        airworthinessItem: inspection?.airworthinessItem,
        dueMethod: inspection?.dueMethod === InspectionDueType.Unknown ? 'Date' : webFormat(inspection?.dueMethod as string),
        dueDate: inspection?.dueDate,
        doesRepeat: inspection?.doesRepeat,
        position: inspection?.position+1,

        dueEveryDate: '0',
        dateType: inspection?.dueDateDaysFrequency ? 'DAYS' : 'MONTHS',
        warnAtDate: '0',
        
        intervalId: '',
        dueEveryInterval: '0',
        warnAtInterval: '0',
    }); 
    useEffect(() => {
        if(inspection && intervals){
            const inspectionName = inspection?.dueInterval ? Object.keys(inspection?.dueInterval)[0] : '';
            const intervalId = intervals.find((interval) => interval.name === inspectionName)?.id;
            setInitialValues({
                name: inspection?.name,
                description: inspection?.description,
                airworthinessItem: inspection?.airworthinessItem,
                dueMethod: webFormat(inspection?.dueMethod as string),
                dueDate: inspection?.dueDate,
                doesRepeat: inspection?.doesRepeat,
                dueEveryDate: inspection?.dueDateDaysFrequency ? inspection?.dueDateDaysFrequency?.toString() ?? '0' 
                    : inspection?.dueDateMonthsFrequency?.toString() ?? '0',
                dateType: inspection?.dueDateDaysFrequency ? 'DAYS' : 'MONTHS',
                warnAtDate: inspection.dueDateSoon?.toString() ?? '0',
                intervalId: intervalId ?? intervals[0].id ?? '',
                warnAtInterval: inspection?.dueInterval?.[inspectionName]?.dueSoon?.toString() ?? '0',
                dueEveryInterval: inspection?.dueInterval?.[inspectionName]?.dueEvery?.toString() ?? '0',
                position: inspection?.position+1,
            });
        };
    }, [inspection, intervals]);

  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
    const selectInterval = intervals?.find((interval) => interval.id === values.intervalId);
    const inspectionInput: InspectionUpdateInput = {
        id,
        name: values.name,
        description: values.description,
        position:  values.position ? (values.position-1) : 0,
        ...(values.doesRepeat ?
            {
                ...( values.dueMethod !== 'Interval' && {
                    ...( values.dateType === 'DAYS' && { dueDateDaysFrequency: Number(values.dueEveryDate), dueDateMonthsFrequency: null }),
                    ...( values.dateType === 'MONTHS' && { dueDateMonthsFrequency: Number(values.dueEveryDate), dueDateDaysFrequency: null }),
                    dueDateSoon: Number(values.warnAtDate),
                }),
            }
            : { dueDate: values.dueDate, dueDateDaysFrequency: null, dueDateMonthsFrequency: null, dueDateSoon: null } ),
        ...( values.dueMethod !== 'Date' && {
            dueInterval: { [selectInterval.name]: { "dueEvery": Number(values.dueEveryInterval), "dueSoon": Number(values.warnAtInterval) } },
        }),
        airworthinessItem: values.airworthinessItem,
        doesRepeat: values.dueMethod === 'Date' ? values.doesRepeat : true,
        dueMethod: values.dueMethod.toUpperCase() as InspectionDueType,
    };
    if(inspectionInput.dueMethod !== InspectionDueType.Date && inspectionInput.dueMethod !== InspectionDueType.Unknown && inspection?.newestIntervalDueRecord?.id ){
        await updateIntervalsOnInspections({ variables: { input: { id:inspection.newestIntervalDueRecord.id  } } });
    }
    await updateInspection({ variables: { input: inspectionInput } });
    setSubmitting(false);
    closeFlyout(false);
  };

  return (
    <>
        {modal && <Modal message="Are sure you want to delete this inspection?" cancel={setModal} accept={ () => deleteInspection({ variables: { id } }) } />}
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, setFieldValue, values }) => (
            <Form className='p-2'>
            <Card>
                <div className="flex flex-col md:flex-row gap-2">
                <div className="flex flex-col w-full">
                    <FullFormikInput name="name" label="Name*" autoComplete="off" />
                    <FullFormikInput name="position" label="Position" autoComplete="off" />
                    <div className='flex justify-start my-3'>
                        <FullFormikCheckBox isVertical={true} name="airworthinessItem" label="Aircraft is grounded when overdue" />
                    </div>
                    <SegmentControl activeItem={values.dueMethod} items={['Date', 'Interval', 'Both']} onChange={ (item) => setFieldValue('dueMethod', item) } />
                </div>
                </div>
                { (values.dueMethod !== 'Interval') && <div className='my-2'>
                    <span className="font-bold text-brand-dark uppercase">Date</span>
                    <hr className="my-2" />
                    {values.dueMethod === 'Date' && <div className='flex justify-start mt-3 mb-4'>
                        <FullFormikCheckBox isVertical={true} name="doesRepeat" label="Recurring?" />
                    </div>}
                    { (values.doesRepeat || values.dueMethod === 'Both') ? <>
                    <div className='flex gap-10 md:flex-row flex-col'>
                        <FullFormikSelect name="dateType" label="Date Type:" autoComplete="off" >
                            <option value="DAYS">Days</option>
                            <option value="MONTHS">Months</option>
                        </FullFormikSelect>
                        <FullFormikInput name="dueEveryDate" label="Due Every:" autoComplete="off" />
                        <FullFormikInput name="warnAtDate" label="Warn (days):" autoComplete="off" />
                    </div>
                    </> : 
                    <div className="flex justify-between py-2 border-b border-slate-100 items-center">
                        <FormLabel className="text-sm font-medium text-slate-500">Due Date</FormLabel>
                        <Field as={DateTimePicker} name="dueDate" />                  
                    </div> }
                </div> }
                { (values.dueMethod === 'Interval' ||  values.dueMethod === 'Both') && <div className='my-2'>
                    <span className="font-bold text-brand-dark uppercase">Interval</span>
                    <hr className="my-2" />
                    <div className='flex gap-10 md:flex-row flex-col'>
                        <FullFormikSelect name="intervalId" label="Interval Name:" autoComplete="off" >
                            { intervals?.map( (interval) => <option key={interval.id} value={interval.id}>{interval.name}</option> ) }
                        </FullFormikSelect>
                        <FullFormikInput name="dueEveryInterval" label="Due Every:" autoComplete="off" />
                        <FullFormikInput name="warnAtInterval" label="Warn At:" autoComplete="off" />
                    </div>
                </div> }
            </Card>
            <div className="px-4 mt-4 flex items-center justify-end gap-4">
                <Button text="Delete" color="red" size="xs" type='button' onClick={() => setModal(true)} disabled={isSubmitting} />
                <Button text="Save" color="navy" size="xs" type='submit' disabled={isSubmitting} />
            </div>
            </Form>
        )}
        </Formik>
    </>
  );
};

export default EditInspectionFlyout;
