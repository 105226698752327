import { colors } from 'utils/statuses';

function UserIcon({ iconSize, iconText, iconId, iconColor, className }) {
  const mergeClasses = function () {
    let size = {
        xs: 'h-6 w-6 text-xs ',
        sm: 'h-8 w-8 ',
        md: 'h-9 w-9 ',
        lg: 'h-10 w-10 text-lg ',
        xl: 'h-12 w-12 text-xl ',
        xxl: 'h-16 w-16 text-2xl ',
      },
      color: string;
    if (iconColor === 'cust') {
      color = 'bg-brand-pale text-brand-electric ';
    } else if (colors.includes(iconColor)) {
      color = `bg-${iconColor}-500 text-white `;
    } else {
      color = 'bg-brand-electric text-white ';
    }
    return size[iconSize] + color + className;
  };
  let mergedClasses = mergeClasses();
  let txtArr = iconText.trim().split(' ');
  let initials = (txtArr?.at(0)?.at(0) ?? '') + (txtArr?.at(-1)?.at(0) ?? '');
  
  return (
    <div
      id={iconId}
      title={iconId}
      className={`flex items-center justify-center font-bold rounded-full shrink-0 first:ml-0 -ml-2 hover:z-40 ${mergedClasses}`}>
      {txtArr?.at(0) == "undefined" ?  "" : initials}
    </div>
  );
}

export default UserIcon;
