import { useMutation, useQuery } from '@apollo/client';
import {
  GetCraftDocument,
  GetComponentsDocument,
  CreateLogBookDocument,
  LogBookCreateInput,
  GetIntervalsForCraftDocument,
  GetLogbooksDocument,
  IntervalType,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import Card from 'components/Card/Card';
import { FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import Multiselect from 'components/Multiselect/Multiselect';

const StyledSelect = (props) => (
  <select
    className="rounded border px-2 w-2/3 block pl-3 pr-10 py-2 text-sm border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    {...props} ></select>
);


function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const New = function ({ closeFlyout }: { closeFlyout: () => void}) {
  const { craftId, user } = useSession();
  const [createLogBook] = useMutation(CreateLogBookDocument, {
    refetchQueries: [{ query: GetLogbooksDocument, variables: { craftId: craftId } }],
  });
  const { data: { craft } = { craft: undefined } } = useQuery(GetCraftDocument, { variables: { craftId }, pollInterval: 0 });
  const { data: { craft : { normalIntervals: intervals } = {} } = {} } = useQuery(GetIntervalsForCraftDocument, { variables: { craftId }, pollInterval: 0 });
  const { data: { components } = { components: [] } } = useQuery(GetComponentsDocument, { variables: { craftId }, pollInterval: 0 });
  const [prefill, setPrefill] = useState({
    title: '',
    description: '',
    component: '',
    intervals: [],
  });

  useEffect(() => {
    if (components && components.length) {
      setPrefill((prev) => ({ ...prev, component: components[0].id }));
    }
  }, [components]);

  const handleSubmit = async (values: typeof prefill, { setSubmitting }: FormikHelpers<typeof prefill>) => {    
    const logBookInput: LogBookCreateInput = {
      name: values.title,
      description: values.description,
      craft: { connect: { id: craftId } },
      components: { connect: [{ id: values.component }] },
      ...( values.intervals.length > 0 && {intervals: { connect: values.intervals.map((interval) => ({
        id: interval.value.id
      })) } } )
    };
    await createLogBook({ variables: { input: logBookInput } });
    setSubmitting(false);
    setPrefill({ component: prefill.component, ...prefill });
    closeFlyout();
  };

  return (
    <Formik enableReinitialize initialValues={prefill} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Form>
            <Card className='m-4'>
              <dl>
                <div className="p-4 flex items-center justify-center w-full">
                  <FullFormikInput name='title' label='Title' isVertical={true} className='w-full' />
                </div>
                <div className="p-4 flex items-center justify-center w-full">
                  <FullFormikInput name='description' label='Description' isVertical={true} className='w-full text-sm' />
                </div>
                <div className="p-4 flex items-center justify-center">
                  <dt className="text-sm font-medium text-slate-500 w-1/3">Component</dt>
                  <Field as={StyledSelect} name="component">
                    {craft?.components.map((component) => (
                      <option key={component.id} value={component.id}>
                        {component.name}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="p-4 flex items-center justify-between w-full">
                  <dt className="text-sm font-medium text-slate-500 w-1/3">Intervals</dt>
                  <div className='w-full ml-14'>
                    <Multiselect initialSelected={[]} items={intervals?.filter((interval) => interval.intervalType == IntervalType.Normal)?.map((interval) => ( 
                      { name: interval.name, value: interval }
                    ))} setSelectedItems={(items) => setFieldValue('intervals', items)} />
                  </div>
                </div>
              </dl>
            </Card>
            <div className="px-4 mb-10 flex items-center justify-end">
              <Button
                type="submit"
                size='xs'
                disabled={isSubmitting}
                text='Create Logbook' />
            </div>
          </Form>
        )
    }}
    </Formik>
  );
};

export default New;
