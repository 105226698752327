import { FolderIcon, StarIcon, ArrowUpTrayIcon, ArrowDownTrayIcon, BriefcaseIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon, StarIcon as OutlineStarIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSession } from 'contexts';
import { GetDocumentsDocument, UpdateDocumentDocument } from 'graphql/generated';
import { useMutation, useQuery } from '@apollo/client';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { DocumentsView } from 'components/Documents/Documents';
import Button from 'components/Button/Button';
import Paywall from 'components/Paywall/Paywall';
import DragAndDrop from './Components/DragAndDrop';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import { AbilityContext } from 'contexts/AbilityContext';
import { permissionHandler } from 'utils/PermissionHandler';
import sanitizeQuery from 'utils/querySanitizer';

const maxSize = 250 * 1024 * 1024; // Max Upload File 250MB in bytes
export const imageMime = 'image/jpeg, image/png, image/gif, application/pdf, image/heic, image/heif';
const Documents = function () {  
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { craftId, organizationSubtype } = useSession();
  const [ query, setQuery ] = useState('');
  const { toastProps, showToast } = useToast();
  const { data : { documents = [] } = {}, refetch, loading } = useQuery( GetDocumentsDocument, { variables: { where: { craftId :{ equals: craftId }, ...(query &&  {name: { startsWith: sanitizeQuery(query) }} ) } } } );
  const ability = useContext(AbilityContext);
  const canManageDocuments = permissionHandler('Manage Aircraft Documents', ability);
  const uploadButton = useRef(null);  
  const uploadDocument = async(event) => {
    const formData = new FormData();
    const files = Array.from(event.target.files) as File[];
    files.forEach((file) => {
      if (file.size > maxSize) {
        alert('File(s) size exceeds the 250MB limit.');
        return;
      }
      formData.append('files', file);
    });
    uploadButton.current.value = '';
    formData.append('craftId', craftId );
    const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
    if (res.ok) {
      showToast({ title: 'File uploaded successfully', type: ToastLength.Normal});
      refetch();
    }else{
      console.log(res);
    }
  };

  

  return (
    <>
      <Toast {...toastProps} position='top' />
      <Flyout { ...flyoutProps } />
      <DragAndDrop className='flex flex-col md:px-8' field="craftId" enabled={canManageDocuments} id={craftId} refetch={refetch} showToast={showToast}>
        {/* Header */}
        <div className="flex-rows items-center bg-white border border-slate-300 rounded shadow-blue mb-4">
          <div className="flex justify-between items-center gap-4 p-6">
            <div className="flex items-center">
              <BriefcaseIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
              <h1 className="text-left font-bold text-brand-dark text-xl md:text-2xl">Documents</h1>
            </div>
            {organizationSubtype !== 'FREE' && <div className="flex justify-end gap-2 flex-wrap">
              <div className="relative flex items-center">
                <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
                <input
                  className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-6 w-32 md:w-full"
                  placeholder="Search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              { canManageDocuments && <Button text="Upload Documents" color="navy" size="xs" icon={<ArrowUpTrayIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />} 
                onClick={() => uploadButton.current.click()} /> }
              <input
                type="file"
                ref={uploadButton}
                onChange={uploadDocument}
                style={{ display: 'none' }}
                accept={imageMime} />
            </div>}
          </div>
        </div>
        {/* Body */}
        {organizationSubtype === 'FREE' ? 
          <Paywall /> :
          <DocumentsView {...{ closeFlyout, updateFlyout, data: (documents as any), canManageDocuments }}  />
        }
      </DragAndDrop>
    </> 
  );
};

export default Documents;
