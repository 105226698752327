import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { FullFormikInput } from "components/Form/StandardForm";
import Modal from "components/Modal/Modal";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { DeleteFlightPurposeDocument, GetFlightPurposeDocument, GetFlightPurposesDocument, UpdateFlightPurposeDocument } from "graphql/generated";
import { useEffect, useState } from "react";


const EditFlightPurpose = function ({ closeFlyout, id, length }: { closeFlyout: () => void, id: string, length?: number }) {
    const { organizationId } = useSession();
    const [updateFlightPurpose] = useMutation(UpdateFlightPurposeDocument, { 
        refetchQueries: [{ query: GetFlightPurposesDocument, variables: { where: { organizationId: { equals: organizationId } } } }],
        onCompleted: () => {
            closeFlyout();
        }
    });
    const [ deleteFlightPurpose ] = useMutation(DeleteFlightPurposeDocument, {
        refetchQueries: [{ query: GetFlightPurposesDocument, variables: { where: { organizationId: { equals: organizationId } } } }],
        onCompleted: () => {
            closeFlyout();
        }
    });
    const [ showModal, setShowModal ] = useState(false);
    const { data } = useQuery( GetFlightPurposeDocument, { variables: { id } } );
    const [ initialValues, setInitialValues ] = useState({ 
        name: data?.flightPurpose?.name,
        sort: data?.flightPurpose?.sort+1,
    });
    useEffect(() => {
        if(data) {
            setInitialValues({
                name: data?.flightPurpose?.name,
                sort: data?.flightPurpose?.sort+1,
            })
        }
    }, [data]);
    return (
        <>
            { showModal && <Modal message={'You are about to delete this purpose.'} accept={ () => {
                deleteFlightPurpose({ variables: { id } });
                setShowModal(false);
            } } cancel={() => setShowModal(false)} /> }
            <Formik enableReinitialize
            initialValues={initialValues}
            onSubmit={(values) => updateFlightPurpose({ variables: { input:{ name: values.name, sort: values.sort-1, id, organization: { connect: { id: organizationId } }} } }) }>
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form>
                        {!data?.flightPurpose?.isCustom && <p className="px-4 pt-4 text-xs text-gray-500">This is a standard flight purpose, you cannot change its name or delete it</p>}
                        <Card className="flex flex-col p-4 h-full m-4">
                            <FullFormikInput name="name" label="Name" autoComplete="off" disabled={!data?.flightPurpose.isCustom} />
                            <FullFormikInput name="sort" label="Sort" autoComplete="off" />
                        </Card>
                        <div className="bg-gray-50 px-4 py-5 flex items-center justify-end gap-3">
                            { data?.flightPurpose?.isCustom && <Button text="Delete Purpose" size='xs' color='red' onClick={()=> setShowModal(true)} />}
                            <Button text="Update Purpose" type='submit' size='xs' color='navy' />
                        </div>
                    </Form>
            )}}
            </Formik>
        </>
    );
};


export default EditFlightPurpose;