import { useNavigate } from 'react-router-dom';
import { useSession } from 'contexts/SessionContext';
import { AirplaneIcon, PlusIcon, CaretRightIcon } from '../../../assets/icons';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { GetCraftsDocument, GetOrganizationDocument, HasCompletedWalkthroughDocument } from 'graphql/generated';
import { useQuery } from '@apollo/client';
import Flyout, { useFlyout } from 'components/Flyout/Flyout'
import NewAircraft from './new';
import Button from 'components/Button/Button';
import OveraircraftLimit from './Components/OveraircraftLimit';
import { useContext, useState } from 'react';
import Modal from 'components/Modal/Modal';
import { WalkthroughContext } from '../Walkthrough';

const Crafts = function () {
  const navigate = useNavigate();
  const { setCraftId, organizationId, craftId } = useSession();
  const { formStep, changeStep } = useContext(WalkthroughContext);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { loading, error, data } = useQuery(GetCraftsDocument, {
    variables: { organizationId },
  });
  const { data: { organization } = { } } = useQuery(GetOrganizationDocument, { variables: { id: organizationId } });
  const [showModal, setShowModal] = useState(false);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  const listCrafts = data?.crafts?.map((craft) => ({
    id: craft.id,
    tailNumber: craft?.tailNumber,
    makeModel: (craft?.make ?? '') + ' ' + (craft?.model ?? ''),
    date: new Date(),
    squawks: craft?.squawks?.filter((item) => item?.status?.toLowerCase() !== "resolved" ).length,
    upcoming: craft?.inspections.filter((inspection) => inspection.almostDue).length,
    overdue: craft?.inspections.filter((inspection) => inspection.due).length,
    isAirworthy: craft?.isAirworthy,
  }));

  const selectCraft = function (id) {
    if(formStep === 'selectAircraft') changeStep("selectAircraft", "statusPageOverview", false);
    setCraftId(id);
    navigate('/aircraft');
  };
  return (
    <div className='relative'>
      { organization?.aircraftLimit < data.crafts.length && <OveraircraftLimit limit={organization?.aircraftLimit} crafts={data.crafts} reload={()=>{}} /> }
      { showModal && <Modal message={'Cannot add more aircraft'} submessage='Aircraft limit reached, you need to add more to crafts to your account. Please contact support to add more crafts.' cancel={setShowModal} accept={()=> {}} /> }
      <Flyout {...flyoutProps} />
      <div className="flex flex-col md:px-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-2 pl-4 md:px-0">
          <h2 className="text-brand-dark font-bold text-xl">Aircraft</h2>
          <div className='flex gap-2 items-center'>
            <span className='text-sm'>{data.crafts.length}/{organization?.aircraftLimit}{' (Craft Limit)'}</span>
            <button onClick={()=>  {
              if(organization?.aircraftLimit <= data.crafts.length){
                setShowModal(true);
              }else{
                updateFlyout({ title: 'Add An Aircraft', content: <NewAircraft closeFlyout={closeFlyout} /> });
              }
            }} className={`border bg-white p-1 px-2 rounded flex items-center text-sm font-semibold hover:shadow ${organization?.aircraftLimit <= data.crafts.length && 'opacity-60'}`}><PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]"/>Add Aircraft</button>
          </div>
        </div>
        {/* Aircraft List */}
        <div className="flex flex-wrap w-full">
          {listCrafts?.map((craft, index) => {
            return(
              <div key={index} className={`${ index % 2 === 0 ? 'md:pr-1' : 'md:pl-1' } w-full md:w-1/2 ${formStep === 'selectAircraft' && 'z-[101]'}`}>
                <div onClick={() => selectCraft(craft.id)} className={`flex flex-col w-full p-4 mb-4 md:mb-2 border shadow md:shadow-none md:rounded cursor-pointer ${ craftId === craft?.id? 'bg-brand-gray border-brand' :'bg-white hover:shadow'}`}>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center flex-wrap justify-center">
                      <AirplaneIcon className={`${ !craft.isAirworthy ? 'bg-red-100 text-red-500 border-red-500' : 'bg-brand-pale text-brand-electric border-brand-electric' } mr-3 w-12 h-12 p-3 rounded border`} />
                      <div>
                        <div className="flex items-center -mb-1">
                          <h1 className="font-bold text-2xl">{craft.tailNumber}</h1>
                          <ExclamationTriangleIcon className={`${ !craft.isAirworthy ? '' : 'hidden' } h-6 w-6 text-red-500 py-[0.125rem] stroke-[0.15rem]`} />
                        </div>
                        <span className="text-sm">{craft.makeModel}</span>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-4" />
                  <div className="flex w-full mt-4 flex-wrap gap-2">
                    <span className={`${ craft.squawks > 0 ? 'bg-slate-100' : 'bg-brand-pale text-brand-electric opacity-50' } grow font-semibold text-center text-sm rounded py-1`}>Squawks {craft.squawks}</span>
                    <span className={`${ craft.upcoming > 0 ? 'bg-yellow-100 text-amber-500' : 'bg-brand-pale text-brand-electric opacity-50' } grow font-semibold text-center text-sm rounded py-1 mx-1`}>Upcoming {craft.upcoming}</span>
                    <span className={`
                      ${ craft.overdue > 0 ? 'bg-red-100 text-red-500' : 'bg-brand-pale text-brand-electric opacity-50' } grow font-semibold text-center text-sm rounded py-1`}>Overdue {craft.overdue}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        { data?.crafts?.length === 0 && <div className="flex justify-center items-center p-4 mt-64">
          <Button text="Add a new Aircraft" color="navy" size="sm" icon={<PlusIcon className="h-3 w-3 mr-1 stroke-[0.15rem]"/>} onClick={()=>updateFlyout({ title: 'Add An Aircraft', content: <NewAircraft closeFlyout={closeFlyout} /> })} />
        </div> }
      </div>
    </div>
  );
};

export default Crafts;
