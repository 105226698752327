import { ApolloError, useMutation, useQuery } from '@apollo/client';
import {
  CreateReservationDocument,
  ReservationCreateInput,
  GetCraftsWithUsersDocument,
} from 'graphql/generated';
import { Formik, Form, Field } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { FormLabel, FullFormikCheckBox } from 'components/Form/StandardForm';
import { format } from 'date-fns';
import DatePicker from 'components/DatePicker/DatePicker';
import { useRefetchContext } from 'contexts/RefetchContext';
import PilotSelect from 'views/Part91/Flights/Components/PilotSelect';

const StyledSelect = (props) => (
  <select
    className="rounded border px-2 w-2/3 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    {...props}></select>
);

const ReservationSchema = Yup.object().shape({
  from: Yup.date().required("Required"),
  to: Yup.date().required("Required"),
});

const New = function ({ closeFlyout }: { closeFlyout: () => void }) {

  const { showToast, toastProps } = useToast();
  const { organizationId, craftId, user } = useSession();
  const { refetchList } = useRefetchContext();
  const [initialValues, setInitialValues] = useState({
    from: '',
    to: '',
    pilot: '',
    notes: '',
    craft: '',
    isMaintenance: false,
    allDay: false,
  });
  
  const [createReservation] = useMutation(CreateReservationDocument, {
    refetchQueries: [
      { query: GetCraftsWithUsersDocument, variables: { organizationId } },
    ],
    onCompleted: () => {
      refetchList();
      showToast({ type: ToastLength.Normal, title: 'Reservation Created', subtitle: 'Reservation has been created successfully' });
    },
  });
  const { data: { crafts } = { craft: undefined } } = useQuery(GetCraftsWithUsersDocument, {
    variables: { organizationId },
  });

  const selectedCraft = crafts?.find((craft) => craft.id === craftId) ?? crafts?.[0];
  useEffect(() => {
    if (crafts){
      setInitialValues({
        ...initialValues,
        from: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm'),
        to: format(new Date(new Date().getTime() + 60 * 60 * 1000), 'yyyy-MM-dd\'T\'HH:mm'),
        pilot: selectedCraft?.userProfiles?.find((tempProfile) => tempProfile.id === user?.userOrganizationProfileId)?.id ?? selectedCraft.userProfiles?.[0].id ?? '',
        notes: '',
        craft: selectedCraft?.id ?? '',
        isMaintenance: false,
        allDay: false,
      });
    }
  }, [crafts, craftId]);
  const handleSubmit = async (
    { pilot, ...values }: typeof initialValues,
  ) => {
    //Check if to and from are valid and from is before to
    if(new Date(values.from) > new Date(values.to)){
      showToast({ type:ToastLength.Normal, title:"Invalid Date Range", subtitle:"From date must be before To date" });
      return;
    }
    //Hack to fix timezone issue
    let from = new Date(values.from+':00Z');
    from.setHours(from.getHours() - (Number(user.timezone)));
    let to = new Date(values.to+':00Z');
    to.setHours(to.getHours() - (Number(user.timezone)));


    if(values.allDay){
      to = new Date(values.to);
      from = new Date(values.from);
      from.setHours(from.getHours() - Number(user.timezone) + 12); // Set to 12PM
      to.setHours(to.getHours() - Number(user.timezone) + 12); // Set to 12PM
    }

    const craft = values.craft;
    delete values.craft;
    const reservationInput: ReservationCreateInput = {
      ...values,
      from,
      to,
      craft: { connect: { id: craft } },
      timezone: user.timezone,
      ...(pilot ? { pilot: { connect: { id: pilot } } } : {}),
    };
    try {
      await createReservation({ variables: { input: reservationInput } });  
    } catch (error) {
      const resError = error as ApolloError;
      if(resError.message === "Reservation Conflict"){
        showToast({ type:ToastLength.Normal, title:"Reservation Conflict", subtitle:"Reservation already between time range" });
      }
      return;
    }
    closeFlyout();
    
  };

  return (
    <>
      <Toast {...toastProps} />
      <Formik enableReinitialize 
        initialValues={initialValues}
        validationSchema={ReservationSchema}
        onSubmit={handleSubmit}>
        {({ values, setFieldValue}) => (
          <Form className='p-4'>
            <Card>
              <div className="px-4 py-5 flex items-center justify-between">
                <FormLabel className="text-sm font-medium text-slate-500">From *</FormLabel>
                {values.allDay ?
                  <Field as={DatePicker} className="w-3/5 rounded border-gray-300" name="from" />
                  :
                  <Field as={DateTimePicker} className="w-3/5 rounded border-gray-300" name="from" />
                }
              </div>
              <div className="px-4 py-5 flex items-center justify-between">
              <FormLabel className="text-sm font-medium text-slate-500">To *</FormLabel>
                {values.allDay ?
                  <Field as={DatePicker} className="w-3/5 rounded border-gray-300" name="to" />
                  :
                  <Field as={DateTimePicker} className="w-3/5 rounded border-gray-300" name="to" />
                }
              </div>
              <div className="px-4 py-5 flex items-center justify-between">
                <FullFormikCheckBox opposite={true} isVertical={true} name="allDay" label="Is all day?" />
              </div>
              <div className="px-4 py-5 flex flex-col md:flex-row items-center justify-between">
                <dt className="text-sm font-medium text-gray-500 w-full md:w-1/2 text-left">Aircraft *</dt>
                <Field
                  as={StyledSelect}
                  name="craft">
                  {crafts?.map((craftObj) => (
                    <option key={craftObj.id} value={craftObj.id}>
                      {craftObj.label}
                    </option>
                  ))}
                </Field>
              </div>
              
              <div className='px-4 py-5 flex items-center justify-between'>
                <FormLabel className='w-1/2'>Pilot *</FormLabel>
                <PilotSelect
                  pilot={
                    {
                      name: selectedCraft?.userProfiles?.find( (tempProfile) => tempProfile?.id === values?.pilot )?.userName ?? '',
                      id: values?.pilot,
                    }
                  }
                  setValue={(value)=>setFieldValue('pilot', value)}
                />
              </div>
              <div className="px-4 py-5 flex items-center justify-between">
                <FullFormikCheckBox opposite={true} isVertical={true} name="isMaintenance" label="Schedule for Maintenance" />
              </div>

              <div className="bg-white px-4 py-5 flex items-center justify-center">
                <dt className="text-sm font-medium text-gray-500 w-1/3">Notes</dt>
                <Field as="textarea" name="notes" className="w-2/3 border-gray-300 rounded" />
              </div>
            </Card>
            <div className="bg-gray-50 px-4 py-5 flex items-center justify-end">
              <Button
                onClick={() => handleSubmit(values)}
                size="xs"
                text="Schedule Reservation"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default New;
